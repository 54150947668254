import './Header.css'

import React from 'react'
import {connect} from 'react-redux'

class Header extends React.Component {

    componentDidMount() {
        // console.log(`Header hello = ${this.props.hello}`)
    }

    render() {
        return (
            <header>
                <div className="ui container main-header">
                    <div className="row">
                        <div className="four wide column">
                            <img src="https://www.geekcap.com/images/geekcap-logo.png" className="logo" alt="logo"/>
                        </div>
                        {/*<div className="twelve wide column">*/}
                        {/*    <div className="ui icon input search-bar-div">*/}
                        {/*        <input type="text" placeholder="Search..."/>*/}
                        {/*        <i className="search icon"></i>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </header>
        )
    }
}

const mapStateToProps = state => {
    return {
        hello: 'hello'
    }
}

export default connect(mapStateToProps)(Header)