import './HomePage.css'

import React from 'react'
import {connect} from 'react-redux'
import {Helmet} from 'react-helmet'
import moment from 'moment'
import ContentItem from './ContentItem'
import {fetchHomePageContent} from './duck'

class HomePage extends React.Component {
    componentDidMount() {
        this.props.fetchHomePageContent()
    }

    /**
     * Helper method, need to externalize this to a utility class since it is copied-and-pasted from the Article component.
     * @param date          The date string to convert to a pretty string.
     * @returns {string}    A pretty version of the date string.
     */
    getPrettyDate = date => {
        // https://devhints.io/moment
        return moment(date, 'YYYYMMDD').format('MMMM D, YYYY')
    }

    getAuthorNameById = authorId => {
        const matchingAuthor = this.props.authors.filter(a => a.id === authorId)
        if (matchingAuthor.length > 0) {
            return matchingAuthor[0].name
        }
        return 'Author'
    }


    /**
     * Renders an individual document, which is either an article or a news item.
     *
     * @param document      The document to render.
     * @returns {*}
     */
    renderDocument = document => {
        return (
            <ContentItem title={document.title}
                         id={document.id}
                         prettyUrl={document.prettyUrl}
                         icon={document.icon}
                         author={this.getAuthorNameById(document.author)}
                         authorId={document.author}
                         date={this.getPrettyDate(document.publishDate)}
                         key={document.id}>
                {document.abstract}
            </ContentItem>
        )
    }

    /**
     * Renders the list of articles retrieved from the database. If there are no articles then this section
     * is not displayed.
     *
     * @returns {*}
     */
    renderArticleSection = () => {
        if (this.props.articles.length === 0) {
            return <div></div>
        }

        return (
            <div className="content-section">
                <div className="ui dividing header"><i className="quote right icon"/>Articles</div>
                <div className="ui items">
                    {this.props.articles.map(document => this.renderDocument(document))}
                </div>
            </div>
        )
    }

    /**
     * Renders the list of news items retrieved from the database. If there are no news items then this section
     * is not displayed.
     *
     * @returns {*}
     */
    renderNewsSection = () => {
        if (this.props.news.length === 0) {
            return <div></div>
        }

        return (
            <div className="content-section">
                <div className="ui dividing header"><i className="newspaper outline icon"/>News</div>
                <div className="ui items">
                    {this.props.news.map(document => this.renderDocument(document))}
                </div>
            </div>
        )
    }

    render() {
        return (
            <div className="ui grid">
                <Helmet>
                    <title>Geekcap</title>
                    <meta name="description" content="By Geeks for Geeks!" />
                    <meta name="og:type" content="website" />
                    <meta name="og:url" content="https://www.geekcap.com" />
                    <meta name="og:title" content="GeekCap" />
                    <meta name="og:site_name" content="GeekCap" />
                    <meta name="og:description" content="By Geeks for Geeks! Technical articles and news relating the Java, Python, React, Cloud, and more." />
                    <meta name="og:image" content="https://www.geekcap.com/images/geekcap-logo.png" />
                </Helmet>
                <div className="row">
                    <div className="sixteen wide column">
                        {this.renderNewsSection()}
                        {this.renderArticleSection()}
                    </div>
                    {/*<div className="four wide column"></div>*/}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        articles: state.home.articles,
        news: state.home.news,
        authors: state.home.authors
    }
}

export default connect(mapStateToProps, {fetchHomePageContent})(HomePage)