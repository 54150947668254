import {combineReducers} from 'redux'
import types from './types'

const contentReducer = (state = '', action) => {
    switch (action.type) {
        case types.FETCH_CONTENT:
            return action.payload
        default:
            return state
    }
}

const articleReducer = (state = '', action) => {
    switch (action.type) {
        case types.FETCH_ARTICLE:
            if (action.payload)
                return action.payload
            else return state
        default:
            return state
    }
}

const authorReducer = (state = {}, action) => {
    switch (action.type) {
        case types.FETCH_AUTHOR:
            if (action.payload)
                return action.payload
            else return state
        default:
            return state
    }
}

export default combineReducers({
    article: articleReducer,
    content: contentReducer,
    author: authorReducer
})