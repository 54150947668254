import documentApi from '../../../api/documentApi'
import authorApi from '../../../api/authorApi'
import types from './types'
import _ from 'lodash'


export const fetchArticles = () => async dispatch => {
    const response = await documentApi.get(`/documents?documentType=article&count=5&numberOfMonths=3`)
    dispatch({type: types.HOME_FETCH_ARTICLES, payload: response.data})
}

export const fetchNews = () => async dispatch => {
    const response = await documentApi.get(`/documents?documentType=news&count=5&numberOfMonths=3`)
    dispatch({type: types.HOME_FETCH_NEWS, payload: response.data})
}

// export const fetchAuthor = authorId => async dispatch => {
//     const response = await authorApi.get(`/author/${authorId}`)
//     dispatch({type: types.HOME_FETCH_AUTHOR, payload: response.data})
// }

export const fetchAuthor = authorId => async dispatch => _fetchAuthor(dispatch, authorId)
const _fetchAuthor = _.memoize(async (dispatch, authorId) => {
    const response = await authorApi.get(`/author/${authorId}`)
    dispatch({type: types.HOME_FETCH_AUTHOR, payload: response.data})
})


// export const fetchHomePageContent = () => dispatch => {
//     // Retrieve articles and news
//     dispatch(fetchArticles())
//     dispatch(fetchNews())
// }

export const fetchHomePageContent = () => async (dispatch, getState) => {
    // Dispatch our two actions and wait for them to complete
    await dispatch(fetchArticles())
    await dispatch(fetchNews())

    // Extract the list of unique author ids from the articles and news state object, which is populated by the
    // fetchArticles() and fetchNews () actions
    const documents = getState().home.articles.concat(getState().home.news)
    _.chain(documents)
        .map('author')
        .uniq()
        .forEach(id => dispatch(fetchAuthor(id)))
        .value()
}
