import './Article.css'

import React from 'react'
import {connect} from 'react-redux'
import Highlight from 'react-highlight'
import {Link} from 'react-router-dom'
import {fetchArticleAndContent} from './duck'
import moment from 'moment'
import {Helmet} from 'react-helmet'


class Article extends React.Component {

    componentDidMount() {
        const {id} = this.props.match.params
        // console.log(`Article ID: ${id}`)
        // this.props.fetchContent(id)
        this.props.fetchArticleAndContent(id)
    }

    getPrettyDate = date => {
        // https://devhints.io/moment
        return moment(date, 'YYYYMMDD').format('MMMM D, YYYY')
    }

    render() {

        // Render a loading screen if we haven't populated all of our fields
        if (!this.props.author.name || !this.props.article.title || this.props.content.length === 0) {
            return (
                <div className="loading-div">
                    <div className="ui segment">
                        <div className="ui active inverted dimmer">
                            <div className="ui text loader">Loading</div>
                        </div>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                    </div>
                </div>
            )
        }

        // Build the link to the author image
        let authorImageUrl = 'images/steven-haines.png'
        if (this.props.author.imageUri) {
            authorImageUrl = `https://www.geekcap.com/content${this.props.author.imageUri}`
        }

        console.log(`article = ${JSON.stringify(this.props.article)}`)

        // Build the link to the author page
        const authorLink = `/author/${this.props.author.id}`

        const articleUrl = `https://www.geekcap.com/article/${this.props.article.prettyUrl}`

        // Render the article
        return (
            <div className="article">
                <Helmet>
                    <title>{this.props.article.title}</title>
                    <meta name="description" content={this.props.article.abstract} />
                    <meta name="og:type" content="website" />
                    <meta name="og:url" content={articleUrl} />
                    <meta name="og:title" content={this.props.article.title} />
                    <meta name="og:site_name" content="GeekCap" />
                    <meta name="og:description" content={this.props.article.abstract} />
                    <meta name="og:image" content="https://www.geekcap.com/images/geekcap-logo.png" />
                </Helmet>
                <div className="ui grid">
                    <div className="row">
                        <div className="sixteen wide column">
                            <h2 className="article-title">{this.props.article.title}</h2>
                            <h4 className="article-subtitle">{this.props.article.subtitle}</h4>
                            <div className="article-author">
                                <img className="article-author-image" src={authorImageUrl} alt="Author"/>
                                <div className="article-attribution">By <Link
                                    to={authorLink}>{this.props.author.name}</Link></div>
                                <p><span
                                    className="article-date">{this.getPrettyDate(this.props.article.publishDate)}</span>
                                </p>
                            </div>

                            <div className="article-body">
                                <Highlight innerHTML={true}>
                                    {this.props.content}
                                </Highlight>
                            </div>
                        </div>
                        {/*<div className="four wide column">*/}
                            {/*Other content*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        article: state.article.article,
        content: state.article.content,
        author: state.article.author
    }
}

export default connect(mapStateToProps, {fetchArticleAndContent})(Article)