import contentApi from '../../../api/contentApi'
import documentApi from '../../../api/documentApi'
import types from './types'

export const fetchCampusContent = campusName => async dispatch => {
    const response = await contentApi.get(`/campus/${campusName}.html`)
    dispatch({type: types.CAMPUS_FETCH_CONTENT, payload: response.data})
}

export const fetchDocumentsByCampus = campusName => async dispatch => {
    const response = await documentApi.get(`/documents?campus=${campusName}`)
    dispatch({type: types.CAMPUS_FETCH_DOCUMENTS, payload: response.data})
}

export const setCampusName = campusName => dispatch => {
    dispatch({type: types.CAMPUS_SET_NAME, payload: campusName})
}

export const fetchCampusPageContent = campusName => dispatch => {
    // Set the campus name
    dispatch(setCampusName(campusName))

    // Retrieve the campus content (HTML document)
    dispatch(fetchCampusContent(campusName))

    // Retrieve the articles in the campus
    dispatch(fetchDocumentsByCampus(campusName))
}