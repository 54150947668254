import './DocumentList.css'

import React from 'react'
import moment from 'moment'
import {Link} from "react-router-dom"

class DocumentList extends React.Component {

    /**
     * Helper method, need to externalize this to a utility class since it is copied-and-pasted from the Article component.
     * @param date          The date string to convert to a pretty string.
     * @returns {string}    A pretty version of the date string.
     */
    getPrettyDate = date => {
        // https://devhints.io/moment
        return moment(date, 'YYYYMMDD').format('MMMM D, YYYY')
    }


    /**
     * Rreturns a div to display a single document, either news or article.
     * @param document  The document to render
     * @returns {*}
     */
    renderDocument = document => {
        const documentLink = `/article/${document.prettyUrl}`
        const iconUrl = `https://www.geekcap.com${document.icon}`
        return (
            <div className="item" key={document.id}>
                <div className="content">
                    <img className="left floated mini ui image" src={iconUrl} alt="Article Logo"/>
                    <div className="header content-item-title">
                        <Link to={documentLink}>{document.title}</Link>
                    </div>
                    <div className="meta">
                        {/*{document.publishDate}*/}
                        {this.getPrettyDate(document.publishDate)}
                    </div>
                    <div className="description">
                        {document.abstract}
                    </div>
                </div>
            </div>
        )
    }

    /**
     * If there is article content then this function renders a news header and a summary of the articles, otherwise
     * it returns an empty div.
     * @returns {*}
     */
    renderArticleSection = () => {
        if (!this.props.documents || this.props.documents.length === 0) {
            return <div></div>
        }

        const articles = this.props.documents.filter(document => document.documentType === 'article')
        if (articles.length === 0) {
            return <div></div>
        }

        return (
            <div className="document-list-document-section">
                <div className="ui dividing header"><i className="quote right icon"/>Articles</div>
                <div className="ui items">
                    {articles.map(document => this.renderDocument(document))}
                </div>
            </div>
        )
    }

    /**
     * If there is news content then this function renders a news header and a summary of the news items, otherwise
     * it returns an empty div.
     * @returns {*}
     */
    renderNewsSection = () => {
        if (!this.props.documents || this.props.documents.length === 0) {
            return <div></div>
        }

        const newsItems = this.props.documents.filter(document => document.documentType === 'news')
        if (newsItems.length === 0) {
            return <div></div>
        }

        return (
            <div className="document-list-document-section">
                <div className="ui dividing header"><i className="newspaper outline icon"/>Articles</div>
                <div className="ui items">
                    {newsItems.map(document => this.renderDocument(document))}
                </div>
            </div>
        )
    }


    render() {
        return (
            <div>
                {this.renderNewsSection()}
                {this.renderArticleSection()}
            </div>
        )
    }
}

export default DocumentList