import './ContentItem.css'

import React from 'react'
import {Link} from 'react-router-dom'

const ContentItem = props => {
    const articleLink = `/article/${props.prettyUrl}`
    const authorLink = `/author/${props.authorId}`
    return (
        <div className="item">
            <div className="content">
                <img className="left floated mini ui image" src={props.icon} alt="Article Logo"/>
                <div className="header content-item-title">
                    <Link to={articleLink}>{props.title}</Link>
                </div>
                <div className="meta">
                    By <Link to={authorLink}>{props.author}</Link> on {props.date}
                </div>
                <div className="description">
                    {props.children}
                </div>
            </div>
        </div>
    )
}

export default ContentItem