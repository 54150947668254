import authorApi from '../../../api/authorApi'
import documentApi from '../../../api/documentApi'
import types from './types'

/**
 * Retrieves the author with the specified authorId.
 * @param authorId      The ID of the author to fetch
 * @returns {Function}
 */
export const fetchAuthor = authorId => async dispatch => {
    const response = await authorApi.get(`/author/${authorId}`)
    dispatch({type: types.AUTHOR_FETCH_AUTHOR, payload: response.data})
}

/**
 * Retrieves all documents by the specified author.
 * @param authorId      The ID of the author for which to retrieve his documents.
 * @returns {Function}
 */
export const fetchDocumentsByAuthor = authorId => async dispatch => {
    const response = await documentApi.get(`/documents?author=${authorId}`)
    dispatch({type: types.AUTHOR_FETCH_ARTICLES, payload: response.data})
}

/**
 * Operation: loads the specified author and all of his documents.
 * @param authorId      The ID of the author for which to load his profile and documents.
 * @returns {Function}
 */
export const fetchAuthorPageContent = authorId => async (dispatch, getState) => {
    // Retrieve the author
    await dispatch(fetchAuthor(authorId))

    // Retrieve the author's documents (might be able to dispatch both at the same time)
    if (getState().author.author.id) {
        dispatch(fetchDocumentsByAuthor(authorId))
    }
}
