import {combineReducers} from 'redux'
import types from './types'

const authorReducer = (state = {}, action) => {
    switch (action.type) {
        case types.AUTHOR_FETCH_AUTHOR:
            return action.payload
        default:
            return state
    }
}

const documentsReducer = (state = [], action) => {
    switch (action.type) {
        case types.AUTHOR_FETCH_ARTICLES:
            if (action.payload)
                return action.payload
            else return state
        default:
            return state
    }
}

export default combineReducers({
    documents: documentsReducer,
    author: authorReducer
})