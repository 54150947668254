import {combineReducers} from 'redux'
import types from './types'

const documentsReducer = (state = [], action) => {
    switch (action.type) {
        case types.CAMPUS_FETCH_DOCUMENTS:
            return action.payload
        default:
            return state
    }
}

const contentReducer = (state = {}, action) => {
    switch (action.type) {
        case types.CAMPUS_FETCH_CONTENT:
            return action.payload
        default:
            return state
    }
}

const campusNameReducer = (state = '', action) => {
    switch (action.type) {
        case types.CAMPUS_SET_NAME:
            return action.payload
        default:
            return state
    }
}

export default combineReducers({
    documents: documentsReducer,
    content: contentReducer,
    campusName: campusNameReducer
})