import authorReducers from './reducers'

// Export our actions
export {
    fetchAuthor,
    fetchDocumentsByAuthor,
    fetchAuthorPageContent
} from './actions'

// Export our types
export {default as authorTypes} from './types'

// Export our reducers
export default authorReducers
