import contentApi from '../../../api/contentApi'
import documentApi from '../../../api/documentApi'
import authorApi from '../../../api/authorApi'
import types from './types'

export const fetchContent = contentId => async dispatch => {
    const response = await contentApi.get(`/${contentId}/index.html`)
    dispatch({type: types.FETCH_CONTENT, payload: response.data})
}

export const fetchArticleByPrettyUrl = prettyUrl => async dispatch => {
    const response = await documentApi.get(`/documents?prettyUrl=${prettyUrl}`)
    dispatch({type: types.FETCH_ARTICLE, payload: response.data[0]})
}

export const fetchAuthor = authorId => async dispatch => {
    const response = await authorApi.get(`/author/${authorId}`)
    dispatch({type: types.FETCH_AUTHOR, payload: response.data})
}


export const fetchArticleAndContent = prettyUrl => async (dispatch, getState) => {
    await dispatch(fetchArticleByPrettyUrl(prettyUrl))

    // Fetch the content for this article
    const contentId = getState().article.article.contentId
    if (contentId) {
        dispatch(fetchContent(contentId))
    }

    const authorId = getState().article.article.author
    if (authorId) {
        dispatch(fetchAuthor(authorId))
    }
}