import './Campus.css'

import React from 'react'
import {connect} from 'react-redux'
import {fetchCampusPageContent} from './duck'
import DocumentList from '../widgets/DocumentList'
import {Helmet} from "react-helmet";

class Campus extends React.Component {

    componentDidMount() {
        const {name} = this.props.match.params
        console.log(`Campus: ${name}`)
        this.props.fetchCampusPageContent(name)
    }

    titleCase(str) {
        str = str.toLowerCase()
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        return str.join(' ')
    }

    render() {
        if (!this.props.documents || this.props.documents.length === 0) {
            return <div></div>
        }
        return (
            <div>
                <Helmet>
                    <title>Geekcap</title>
                    <meta name="description" content="By Geeks for Geeks!" />
                    <meta name="og:type" content="website" />
                    <meta name="og:url" content={`https://www.geekcap.com/campus/${this.props.campusName}`} />
                    <meta name="og:title" content={`GeekCap - ${this.titleCase(this.props.campusName)} Campus`} />
                    <meta name="og:site_name" content="GeekCap" />
                    <meta name="og:description" content={`${this.titleCase(this.props.campusName)} Campus. Learn about everything ${this.props.campusName}!`} />
                    <meta name="og:image" content="https://www.geekcap.com/images/geekcap-logo.png" />
                </Helmet>
                {/*<h2>{this.props.campusName}</h2>*/}
                <div className="campus-header">
                    <div dangerouslySetInnerHTML={{__html: this.props.content}}/>
                </div>
                <DocumentList documents={this.props.documents} />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        campusName: state.campus.campusName,
        documents: state.campus.documents,
        content: state.campus.content
    }
}

export default connect(mapStateToProps, {fetchCampusPageContent})(Campus)