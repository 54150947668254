import './App.css'

import React from 'react'
// import {HashRouter, Route, Switch} from "react-router-dom"
import {BrowserRouter, Route, Switch} from "react-router-dom"
// import {Router, Route, Switch} from "react-router-dom"
import ReactGA from 'react-ga'

import Header from './header/Header'
import NavBar from './navbar/NavBar'
import HomePage from './homepage/HomePage'
import Campus from './campus/Campus'
import Article from './article/Article'
import Author from './author/Author'

// const MEASUREMENT_ID = 'G-0EW3QFM1BE'
const TRACKING_ID = 'UA-198013028-1'
ReactGA.initialize(TRACKING_ID)

const App = () => {
    return (
        <div>
            <BrowserRouter>
                <div>
                    <Header />
                    <NavBar/>
                    <div className="ui container body-content">
                        <Switch>
                            {/*<Route path="/note/:id" component={Note}/>*/}
                            <Route path="/campus/:name" exact component={Campus}/>
                            <Route path="/article/:id" exact component={Article}/>
                            <Route path="/author/:id" exact component={Author}/>
                            <Route path="/" exact component={HomePage}/>
                        </Switch>
                    </div>
                </div>
            </BrowserRouter>
        </div>
    )
}

export default App