import campusReducers from './reducers'

// Export our actions
export {
    fetchCampusPageContent
} from './actions'

// Export our types
export {default as campusTypes} from './types'

// Export our reducers
export default campusReducers
