import {combineReducers} from 'redux'
import types from './types'

const articlesReducer = (state = [], action) => {
    switch (action.type) {
        case types.HOME_FETCH_ARTICLES:
            return action.payload
        default:
            return state
    }
}

const newsReducer = (state = [], action) => {
    switch (action.type) {
        case types.HOME_FETCH_NEWS:
            return action.payload
        default:
            return state
    }
}

const authorsReducer = (state = [], action) => {
    switch (action.type) {
        case types.HOME_FETCH_AUTHOR:
            return [...state, action.payload]
        default:
            return state
    }
}

export default combineReducers({
    articles: articlesReducer,
    news: newsReducer,
    authors: authorsReducer
})