import './Author.css'

import React from 'react'
import {connect} from 'react-redux'
import {fetchAuthorPageContent} from './duck'
import {Link} from "react-router-dom"
import moment from 'moment'
import {Helmet} from "react-helmet";

class Author extends React.Component {

    componentDidMount() {
        const {id} = this.props.match.params
        // console.log(`Author ID: ${id}`)

        // Load our page contents: author and his documents
        this.props.fetchAuthorPageContent(id)
    }

    /**
     * Helper method, need to externalize this to a utility class since it is copied-and-pasted from the Article component.
     * @param date          The date string to convert to a pretty string.
     * @returns {string}    A pretty version of the date string.
     */
    getPrettyDate = date => {
        // https://devhints.io/moment
        return moment(date, 'YYYYMMDD').format('MMMM D, YYYY')
    }


    /**
     * Rreturns a div to display a single document, either news or article.
     * @param document  The document to render
     * @returns {*}
     */
    renderDocument = document => {
        const documentLink = `/article/${document.prettyUrl}`
        const iconUrl = `https://www.geekcap.com${document.icon}`
        return (
            <div className="item" key={document.id}>
                <div className="content">
                    <img className="left floated mini ui image" src={iconUrl} alt="Article Logo"/>
                    <div className="header content-item-title">
                        <Link to={documentLink}>{document.title}</Link>
                    </div>
                    <div className="meta">
                        {this.getPrettyDate(document.publishDate)}
                    </div>
                    <div className="description">
                        {document.abstract}
                    </div>
                </div>
            </div>
        )
    }

    /**
     * If there is article content then this function renders a news header and a summary of the articles, otherwise
     * it returns an empty div.
     * @returns {*}
     */
    renderArticleSection = () => {
        if (!this.props.containsArticleEntries) {
            return <div></div>
        }

        return (
            <div className="author-document-section">
                <div className="ui dividing header"><i className="quote right icon"/>Articles</div>
                <div className="ui items">
                    {this.renderArticles()}
                </div>
            </div>
        )
    }

    /**
     * If there is news content then this function renders a news header and a summary of the news items, otherwise
     * it returns an empty div.
     * @returns {*}
     */
    renderNewsSection = () => {
        if (!this.props.containsNewsEntries) {
            return <div></div>
        }

        return (
            <div className="author-document-section">
                <div className="ui dividing header"><i className="newspaper outline icon"/>News</div>
                <div className="ui items">
                    {this.renderNews()}
                </div>
            </div>
        )
    }


    renderArticles = () => {
        return this.props.documents
            .filter(document => document.documentType === 'article')
            .map(document => this.renderDocument(document))
    }

    renderNews = () => {
        return this.props.documents
            .filter(document => document.documentType === 'news')
            .map(document => this.renderDocument(document))
    }

    render() {
        let authorImageUrl = 'images/steven-haines.png'
        if (this.props.author.imageUri) {
            authorImageUrl = `https://www.geekcap.com/content${this.props.author.imageUri}`
        }

        // TODO: need to add a new description field to the author object and show it in the Helmet description, rather
        // than hardcode the value
        return (
            <div>
                <Helmet>
                    <title>{this.props.author.name}</title>
                    <meta name="description" content="Steven Haines is a senior technologist, accomplished architect, author and educator. He is currently working at Turbonomic as the Principal Software Architect for their Cloud products."/>
                    <meta name="og:type" content="website" />
                    <meta name="og:url" content={`https://www.geekcap.com/author/${this.props.author.id}`} />
                    <meta name="og:title" content={this.props.author.name} />
                    <meta name="og:site_name" content="GeekCap" />
                    <meta name="og:description" content="Steven Haines is a senior technologist, accomplished architect, author and educator. He is currently working at Turbonomic as the Principal Software Architect for their Cloud products." />
                    <meta name="og:image" content={authorImageUrl} />
                </Helmet>
                <div className="author-bio">
                    <img className="author-bio-image" src={authorImageUrl} alt="Author"/>
                    <div className="author-bio-name">{this.props.author.name}</div>
                </div>
                <div className="author-bio-summary">
                    <div dangerouslySetInnerHTML={{__html: this.props.author.bio}}/>
                </div>

                {this.renderArticleSection()}
                {this.renderNewsSection()}
            </div>
        )
    }
}

const mapStateToProps = state => {
    const documents = state.author.documents
    const containsNewsEntries = documents.filter(document => document.documentType === 'news').length > 0
    const containsArticleEntries = documents.filter(document => document.documentType === 'article').length > 0

    return {
        author: state.author.author,
        documents: documents,
        containsNewsEntries: containsNewsEntries,
        containsArticleEntries: containsArticleEntries
    }
}

export default connect(mapStateToProps, {fetchAuthorPageContent})(Author)