import './NavBar.css'

import React from 'react'
import {withRouter} from "react-router-dom"
import {connect} from 'react-redux'
import {Menu} from 'semantic-ui-react'
// import ReactGA from 'react-ga'
import {addPageView} from "./duck/actions"

class NavBar extends React.Component {
    state = {historyInitialized: false}
    // historyInitialized = false

    handleItemClick = (e, {name}) => {
        // if (name === this.state.activeItem) {
        //     // Don't try to push a view that is already visible
        //     return
        // }

        // Update the state with the newly selected view
        this.setState({activeItem: name})

        // Push the new view
        switch (name) {
            case 'Header':
                this.props.history.push('/')
                break;
            case 'Architecture':
                this.props.history.push('/campus/architecture')
                break;
            case 'Cloud':
                this.props.history.push('/campus/cloud')
                break;
            case 'Containers':
                this.props.history.push('/campus/containers')
                break;
            case 'Java':
                this.props.history.push('/campus/java')
                break;
            case 'Python':
                this.props.history.push('/campus/python')
                break;
            case 'React':
                this.props.history.push('/campus/react')
                break;
            case 'Mobile':
                this.props.history.push('/campus/mobile')
                break;
            default:
        }
    }

    componentDidMount() {
    }

    render() {
        if (!this.state.historyInitialized && this.props.history) {
            // console.log(`History object: ${this.props.history}`)
            this.props.history.listen((location, action) => {
                // console.log(`History.listen was called: ${JSON.stringify(location)}`)
                this.props.addPageView(location.pathname)
                // ReactGA.set({ page: location.pathname });
                // ReactGA.pageview(location.pathname);
            })

            // console.log(`Analytics initialized. Initial path: ${this.props.location.pathname}`)
            // Set the initial path
            this.props.addPageView(this.props.location.pathname)
            this.setState({historyInitialized: true})
            // this.historyInitialized = true
        }
        const {activeItem} = this.state
        return (
            <div className="header-menu">
                <Menu>
                    <div className="ui container">
                        <Menu.Item header
                                   name='Header'
                                   active={false}
                                   onClick={this.handleItemClick}>
                            <i className="home icon"></i>
                            <p>Home</p>
                        </Menu.Item>
                        {/*<Menu.Item*/}
                        {/*    name='Architecture'*/}
                        {/*    active={activeItem === 'Architecture'}*/}
                        {/*    onClick={this.handleItemClick}*/}
                        {/*/>*/}
                        <Menu.Item
                            name='Cloud'
                            active={activeItem === 'Cloud'}
                            onClick={this.handleItemClick}>
                            <i className="cloud icon"></i>
                            <p>Cloud</p>
                        </Menu.Item>
                        {/*<Menu.Item*/}
                        {/*    name='Containers'*/}
                        {/*    active={activeItem === 'Containers'}*/}
                        {/*    onClick={this.handleItemClick}*/}
                        {/*/>*/}
                        {/*<Menu.Item*/}
                        {/*    name='Java'*/}
                        {/*    active={activeItem === 'Java'}*/}
                        {/*    onClick={this.handleItemClick}*/}
                        {/*/>*/}
                        {/*<Menu.Item*/}
                        {/*    name='Python'*/}
                        {/*    active={activeItem === 'Python'}*/}
                        {/*    onClick={this.handleItemClick}*/}
                        {/*/>*/}
                        {/*<Menu.Item*/}
                        {/*    name='React'*/}
                        {/*    active={activeItem === 'React'}*/}
                        {/*    onClick={this.handleItemClick}*/}
                        {/*/>*/}
                        {/*<Menu.Item*/}
                        {/*    name='Mobile'*/}
                        {/*    active={activeItem === 'Mobile'}*/}
                        {/*    onClick={this.handleItemClick}*/}
                        {/*/>*/}
                    </div>
                </Menu>

            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        hello: 'hello'
    }
}

export default withRouter(connect(mapStateToProps, {addPageView})(NavBar))