import analyticsApi from "../../../api/analyticsApi";

// Analytics supports multiple sites, so set out site to geekcap
const SITE = 'geekcap'

/**
 * Posts a page view to analytics
 * @param pathName  The path of the page view
 * @returns {(function(): Promise<void>)|*}
 */
export const addPageView = pathName => async () => {
    const response = await analyticsApi.post(`/pageview`, {
        site: SITE,
        pathName: pathName
    })
}
