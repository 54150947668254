import {combineReducers} from 'redux'
import articleReducers from '../app/article/duck'
import authorReducers from '../app/author/duck'
import homeReducers from '../app/homepage/duck'
import campusReducers from '../app/campus/duck'

export default combineReducers({
    home: homeReducers,
    article: articleReducers,
    author: authorReducers,
    campus: campusReducers
})